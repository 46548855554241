import FarmerImage from '../Farmer.png'; // Adjust the path as necessary
import GeneratorImage from '../Generator.png'; // Import image for generators
import MachinaryImage from '../Machinary.png'; // Adjust the path as necessary
import LogoImage from '../logo.png'; // Import the logo image
import '../styles/History.css'; // Import CSS if you have any specific styles
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const History = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollToTop) {
      window.scrollTo(0, 0); // Scroll to the top of the page
    }
  }, [location.state]);

  return (
    <div className="history-page">
      <h1>Our Journey</h1>
      <div className="image-text-container">
        <img src={FarmerImage} alt="Farmer" />
        <div className="text">
          <p>
            In { /* Placeholder for the year */ }, Afework International Group began its journey by collaborating closely with local farmers. Our initial focus was on exporting high-quality agricultural products. We worked hand-in-hand with farmers to ensure that their produce met the highest standards of quality before it reached international markets. This commitment to excellence and farmer support laid the foundation for our growth and success in the industry.
          </p>
        </div>
      </div>
      <div className="image-text-container middle-image-right">
        <div className="text">
          <p>
            As we expanded our operations, we recognized the need for reliable power solutions. We began importing top-of-the-line generators, designed to meet the energy needs of various industries and sectors. Our commitment to providing high-performance, durable generators ensured that our clients could rely on uninterrupted power supply, enhancing their operational efficiency and reliability.
          </p>
        </div>
        <img src={GeneratorImage} alt="Generators" />
      </div>
      <div className="image-text-container">
        <img src={MachinaryImage} alt="Machinery" />
        <div className="text">
          <p>
            As we grew, our commitment to enhancing industries expanded. We entered the medical field, specializing in the import of state-of-the-art medical equipment. By partnering with leading manufacturers and suppliers worldwide, we ensure that our clients receive cutting-edge technology that meets rigorous standards of quality and reliability. Our dedication to improving healthcare delivery through advanced equipment underscores our role as a pivotal player in the medical sector.
          </p>
        </div>
      </div>
      <div className="conclusion">
        <p>
          As we reflect on our rich history and the evolution of Afework International Group, we are excited about the future and the new milestones we aim to achieve. From our beginnings in exporting agricultural products to our current focus on importing cutting-edge medical equipment and top-of-the-line generators, our journey has been remarkable. We invite you to be a part of this exciting journey. Join us as we continue to innovate, grow, and make a difference in the world. Your support and collaboration are vital to our success, and together, we can shape the future of our industry. Welcome to Afework International Group—where the future begins today.
        </p>
        <img src={LogoImage} alt="Company Logo" className="logo-image" />
      </div>
    </div>
  );
};

export default History;
