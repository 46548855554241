import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { signInWithPopup, signOut, GoogleAuthProvider, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase-config';
import '../styles/Navbar.css';
import logo from '../logo.png';
import profilePlaceholder from '../Profile.png'; // Import the profile image

const Navbar = () => {
  const [user, setUser] = useState(null);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const navbarHeight = 60;

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  const handleScrollToSection = (section) => {
    if (location.pathname === '/') {
      scroller.scrollTo(section, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: -navbarHeight,
      });
    } else {
      navigate('/', { state: { scrollToSection: section } });
    }
  };

  const handleContactClick = () => handleScrollToSection('contact-section');
  const handleAboutClick = () => handleScrollToSection('about-us-section');
  const handleProductsServicesClick = () => handleScrollToSection('products-services-section');
  const handleHomeClick = () => handleScrollToSection('home');

  const handleProfileClick = () => setShowProfileMenu(prev => !prev);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setShowProfileMenu(false);
      navigate('/'); // Redirect to home after logout
    } catch (error) {
      console.error("Error during logout:", error);
      // Consider displaying an error message to the user here
    }
  };

  const handleLogin = async () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account' // Prompt user to select an account
    });

    try {
      await signInWithPopup(auth, provider);
      setShowProfileMenu(false); // Hide profile menu after login
      navigate('/dashboard'); // Redirect to dashboard after login
    } catch (error) {
      console.error("Error during login:", error);
      // Consider displaying an error message to the user here
    }
  };

  const handleDashboardRedirect = () => {
    setShowProfileMenu(false);
    navigate('/dashboard'); // Navigate to the dashboard page
  };

  return (
    <nav className={`navbar ${location.pathname === '/' ? 'home-navbar' : ''}`}>
      <Link to="/" className="logo-container">
        <img src={logo} alt="Logo" className="logo" />
      </Link>

      <ul className="nav-links">
        <li>
          <button onClick={handleHomeClick} className="nav-link">
            Home
          </button>
        </li>
        <li>
          <button onClick={handleAboutClick} className="nav-link">
            About
          </button>
        </li>
        <li>
          <button onClick={handleProductsServicesClick} className="nav-link">
            Products & Services
          </button>
        </li>
      </ul>

      <div className="right-section">
        <button onClick={handleContactClick} className="button">Contact Us</button>

        <div className="profile-container">
          <button onClick={handleProfileClick} className="profile-button" aria-haspopup="true" aria-expanded={showProfileMenu}>
            <img
              src={user ? user.photoURL : profilePlaceholder}
              alt="User Profile"
              className="profile-icon"
            />
          </button>

          {showProfileMenu && (
            <div className="profile-menu">
              {user ? (
                <>
                  <button 
                    onClick={handleDashboardRedirect} 
                    className="profile-menu-item">
                    Dashboard
                  </button>
                  <button 
                    onClick={handleLogout} 
                    className="profile-menu-item">
                    Logout
                  </button>
                </>
              ) : (
                <button 
                  onClick={handleLogin} 
                  className="profile-menu-item">
                  Login
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
