import React, { useState } from 'react';
import '../styles/Contact.css'; // Import the CSS file for Contact page styling

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    concern: '' // New field for selecting concern
  });
  const [formStatus, setFormStatus] = useState('');
  const [loading, setLoading] = useState(false);

  const emailMap = {
    general: 'finance@afeworkinternational.com',
    medical: 'medical@afeworkinternational.com',
    other: 'manager@afeworkinternational.com'
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormStatus('');
    setLoading(true);

    const selectedEmail = emailMap[formData.concern] || emailMap.general;
    
    fetch(`https://formsubmit.co/${selectedEmail}`, {
      method: 'POST',
      body: new FormData(e.target)
    })
      .then((response) => {
        if (response.ok) {
          setTimeout(() => {
            window.location.href = 'https://www.afeworkinternational.com/'; // Redirect to the homepage
          }, 2000);
        } else {
          setFormStatus('Failed to send message.');
        }
      })
      .catch(() => {
        setFormStatus('Failed to send message.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="contacts-section" id="contact">
      <div className="contact-form">
        <h1 className="CU">Contact Us</h1>
        <form onSubmit={handleSubmit}>
          <input 
            type="hidden" 
            name="_captcha" 
            value="false"
          />
          <input 
            type="hidden" 
            name="_next" 
            value="http://localhost:3000/"
          />
          <input 
            type="text" 
            name="name" 
            placeholder="Your Name" 
            value={formData.name}
            onChange={handleChange}
            required 
          />
          <input 
            type="email" 
            name="email" 
            placeholder="Your Email" 
            value={formData.email}
            onChange={handleChange}
            required 
          />
          <select 
            name="concern" 
            value={formData.concern}
            onChange={handleChange}
            required
          >
            <option value="">Select Your Concern</option>
            <option value="general">General Inquiry</option>
            <option value="medical">Medical</option>
            <option value="other">Other</option>
          </select>
          <textarea 
            name="message" 
            placeholder="Your Message" 
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
          <div className="button-container">
            <button 
              type="submit" 
              className={loading ? 'loading' : ''}
            >
              {loading ? 'Sending...' : 'Send'}
            </button>
          </div>
        </form>
        <div className="form-status-container">
          {formStatus && <p>{formStatus}</p>}
        </div>
      </div>
    </div>
  );
};

export default Contact;
