import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { db, storage } from '../firebase-config';
import { collection, getDocs, addDoc, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import '../styles/Dashboard.css';

const Dashboard = () => {
  const [products, setProducts] = useState([]);
  const [newProduct, setNewProduct] = useState({ title: '', description: '', image: null });
  const [editingProduct, setEditingProduct] = useState(null);
  const [editProductData, setEditProductData] = useState({ title: '', description: '', image: null });
  const [imagePreview, setImagePreview] = useState(null);
  const [showAddProductForm, setShowAddProductForm] = useState(false);
  const [error, setError] = useState('');

  const uploadImage = async (imageFile) => {
    if (!imageFile) return '';

    const uniqueName = `${imageFile.name}-${Date.now()}`;
    const storageRef = ref(storage, `product-images/${uniqueName}`);
    
    try {
      const snapshot = await uploadBytes(storageRef, imageFile);
      return await getDownloadURL(snapshot.ref);
    } catch (error) {
      console.error('Error uploading image:', error);
      setError('Failed to upload image');
      return ''; // Return an empty string if upload fails
    }
  };

  const deleteImage = async (imageUrl) => {
    if (!imageUrl) return;
    
    try {
      const imageRef = ref(storage, imageUrl);
      await deleteObject(imageRef);
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'products'));
        const productsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setProducts(productsList);
      } catch (error) {
        console.error('Error fetching products:', error);
        setError('Failed to fetch products.');
      }
    };
    fetchProducts();
  }, []);

  const validateProductData = (productData) => {
    if (!productData.title || !productData.description) {
      setError('Title and description are required.');
      return false;
    }
    setError('');
    return true;
  };

  const handleAddProduct = async () => {
    if (!validateProductData(newProduct)) return;

    try {
      let imageUrl = newProduct.image ? await uploadImage(newProduct.image) : '';
      await addDoc(collection(db, 'products'), { ...newProduct, image: imageUrl });

      await updateProductList();
      resetForm();
    } catch (error) {
      console.error('Error adding product:', error);
      setError('Failed to add product.');
    }
  };

  const handleDeleteProduct = async (id, imageUrl) => {
    try {
      if (imageUrl) {
        await deleteImage(imageUrl);
      }
      await deleteDoc(doc(db, 'products', id));
      await updateProductList();
    } catch (error) {
      console.error('Error deleting product:', error);
      setError('Failed to delete product.');
    }
  };

  const handleEditProduct = async () => {
    if (!validateProductData(editProductData)) return;

    try {
      let imageUrl = editingProduct.image;
      
      if (editProductData.image) {
        imageUrl = await uploadImage(editProductData.image);
        if (editingProduct.image) {
          await deleteImage(editingProduct.image);
        }
      }

      await updateDoc(doc(db, 'products', editingProduct.id), { ...editProductData, image: imageUrl });

      await updateProductList();
      resetForm();
    } catch (error) {
      console.error('Error updating product:', error);
      setError('Failed to update product.');
    }
  };

  const updateProductList = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'products'));
      const productsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProducts(productsList);
    } catch (error) {
      console.error('Error updating product list:', error);
      setError('Failed to update product list.');
    }
  };

  const startEditing = (product) => {
    setEditingProduct(product);
    setEditProductData({ title: product.title, description: product.description, image: null });
    setImagePreview(product.image || null);
    setShowAddProductForm(true);
  };

  const resetForm = () => {
    setNewProduct({ title: '', description: '', image: null });
    setEditProductData({ title: '', description: '', image: null });
    setImagePreview(null);
    setError('');
    setShowAddProductForm(false);
    setEditingProduct(null);
  };

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        if (editingProduct) {
          setEditProductData(prev => ({ ...prev, image: file }));
        } else {
          setNewProduct(prev => ({ ...prev, image: file }));
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*'
  });

  const handleRemoveImage = () => {
    setImagePreview(null);
    if (editingProduct) {
      setEditProductData(prev => ({ ...prev, image: null }));
    } else {
      setNewProduct(prev => ({ ...prev, image: null }));
    }
  };

  return (
    <div className="dashboard-container">
      <h1>Admin Dashboard</h1>
      {!showAddProductForm && (
        <button onClick={() => setShowAddProductForm(true)} className="button">
          Add Product
        </button>
      )}

      {showAddProductForm && <div className="overlay" onClick={resetForm} />}

      {showAddProductForm && (
        <div className="product-form">
          <h2>{editingProduct ? 'Edit Product' : 'Add New Product'}</h2>
          {error && <p className="error-message-overlay">{error}</p>}
          <input
            type="text"
            value={editingProduct ? editProductData.title : newProduct.title}
            onChange={(e) => {
              if (editingProduct) {
                setEditProductData({ ...editProductData, title: e.target.value });
              } else {
                setNewProduct({ ...newProduct, title: e.target.value });
              }
            }}
            placeholder="Title"
          />
          <input
            type="text"
            value={editingProduct ? editProductData.description : newProduct.description}
            onChange={(e) => {
              if (editingProduct) {
                setEditProductData({ ...editProductData, description: e.target.value });
              } else {
                setNewProduct({ ...newProduct, description: e.target.value });
              }
            }}
            placeholder="Description"
          />
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            {imagePreview ? (
              <div className="image-preview-container">
                <img src={imagePreview} alt="Preview" className="image-preview" />
              </div>
            ) : (
              <p>Drag & drop an image here, or click to select one</p>
            )}
          </div>
          <div className="form-buttons">
            <button onClick={handleRemoveImage} className="remove-image-button">Remove Image</button>
            <button onClick={editingProduct ? handleEditProduct : handleAddProduct} className="save-changes-button">
              {editingProduct ? 'Save Changes' : 'Add Product'}
            </button>
            <button onClick={resetForm} className="cancel-button">Cancel</button>
          </div>
        </div>
      )}

      <div className="product-list">
        <h2>Product List</h2>
        {products.length > 0 ? (
          products.map(product => (
            <div key={product.id} className="product-item">
              <div className="product-image">
                {product.image ? (
                  <img src={product.image} alt={product.title} />
                ) : (
                  <p>No image available</p>
                )}
              </div>
              <div className="product-details">
                <h3>{product.title}</h3>
                <p>{product.description}</p>
              </div>
              <div className="product-actions">
                <button onClick={() => startEditing(product)} className="button edit-button">Edit</button>
                <button onClick={() => handleDeleteProduct(product.id, product.image)} className="button delete-button">Delete</button>
              </div>
            </div>
          ))
        ) : (
          <p>No products available</p>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
