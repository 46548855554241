import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';
import '../styles/Footer.css'; 
const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isFooterVisible, setIsFooterVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollRatio = window.scrollY / (document.documentElement.scrollHeight - window.innerHeight);
      setIsFooterVisible(scrollRatio >= 0.99);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScrollToSection = (section) => {
    if (location.pathname === '/') {
      scroller.scrollTo(section, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      });
    } else {
      navigate('/', { state: { scrollToSection: section } });
    }
  };

  const address = "2Q5C+WJX, Addis Ababa";
  const phoneNumber = "+251115577723";

  const googleMapsUrl = `https://www.google.com/maps/place/${encodeURIComponent(address)}`;

  const formatPhoneNumber = (phoneNumber) => {
    return `tel:${phoneNumber.replace(/\s/g, '')}`;
  };

  return (
    <footer id="footer" className={isFooterVisible ? 'footer show' : 'footer'}>
      <div className="footer-content">
        <div className="contact-us">
          <div className="contact-info">
            <div>
              Office: <a href={googleMapsUrl} className="google-map-link" target="_blank" rel="noopener noreferrer">{address}</a><br />
              Tel: <a href={formatPhoneNumber(phoneNumber)} className="phone-link">{phoneNumber}</a>
            </div>
          </div>
        </div>
        <div className="quick-links">
          <h1 className="footer-text">QUICK LINKS</h1>
          <ul>
          <li>
              <button onClick={() => handleScrollToSection('video-background')} className="footer-link">
                Home
              </button>
            </li>
            <li>
              <button onClick={() => handleScrollToSection('about-us-section')} className="footer-link">
                About
              </button>
            </li>
            <li>
              <button onClick={() => handleScrollToSection('products-services-section')} className="footer-link">
                Products & Services
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div className="company-details">
        <p className="company-text">
          Afework International Group &copy; {new Date().getFullYear()}
        </p>
      </div>
    </footer>
  );
}

export default Footer;
