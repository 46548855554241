// firebase-config.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAKzB4M_TieF9s9nYmkovvivXcyz5biuhE",
  authDomain: "aig-db-ee6de.firebaseapp.com",
  projectId: "aig-db-ee6de",
  storageBucket: "aig-db-ee6de.appspot.com",
  messagingSenderId: "1054549863986",
  appId: "1:1054549863986:web:ce6e0fb50450aef9e2162b",
  measurementId: "G-QKSXDG3WR8"
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const storage = getStorage(app);

export { db, auth, provider, storage };
