import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase-config';
import { collection, getDocs } from 'firebase/firestore';
import '../styles/ProductsServices.css';

const ProductsServices = () => {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'products'));
        const itemsList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setItems(itemsList);
      } catch (error) {
        console.error('Error fetching items:', error);
        setError('Failed to load items. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, []);

  useEffect(() => {
    if (selectedItem) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }

    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [selectedItem]);

  const handleContactUs = () => {
    navigate('/', { state: { scrollToContact: true } });
  };

  const handleOpen = (item) => {
    setSelectedItem(item);
  };

  const handleClose = () => {
    setSelectedItem(null);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredItems = items.filter(item =>
    item.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="products-services-page">
      <div className="header-section">
        <h1>Product Catalogue</h1>
        <p>
          At Afework International Group, we are proud to offer a wide range of high-quality medical equipment and services tailored to meet the needs of healthcare providers worldwide.
        </p>
      </div>

      <div className="header-and-search">
        <div className="search-bar-container">
          <input
            type="text"
            placeholder="Search for products"
            value={searchQuery}
            onChange={handleSearch}
            className="search-bar"
          />
        </div>
      </div>

      <div className="product-service-container list"> {/* Default to list view */}
        {loading ? (
          <p>Loading items...</p>
        ) : error ? (
          <p className="error-message">{error}</p>
        ) : filteredItems.length > 0 ? (
          filteredItems.map(item => (
            <div key={item.id} className="product-service list" onClick={() => handleOpen(item)}>
              <img src={item.image} alt={item.title} />
              <h2>{item.title}</h2>
            </div>
          ))
        ) : (
          <p>No items available.</p>
        )}
      </div>

      {selectedItem && (
        <>
          <div className="overlay" onClick={handleClose}></div>
          <div className={`enlarged-view ${selectedItem ? 'visible' : ''}`}>
            <button className="close-button" onClick={handleClose}>×</button>
            <img className="enlarged-image" src={selectedItem.image} alt={selectedItem.title} />
            <div className="enlarged-text-content">
              <h2>{selectedItem.title}</h2>
              <p>{selectedItem.description}</p>
            </div>
          </div>
        </>
      )}

      <div className="footer-section">
        <p>
          Contact us today to learn more about how we can provide the medical equipment and services your facility needs.
        </p>
        <button className="contact-button" onClick={handleContactUs}>Contact Us</button>
      </div>
    </div>
  );
};

export default ProductsServices;
