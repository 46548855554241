import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Element, scroller } from 'react-scroll';
import '../styles/Home.css';
import '../index.css';
import Contact from './ContactUs';
import FinalVideo from '../Final Video.mp4';
import logo from '../logo.png'; // Import your logo

const Home = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const navbarHeight = 60;

  // Calculate years in operation
  const currentYear = new Date().getFullYear();
  const yearsInOperation = currentYear - 1996; // The company started in 1996

  useEffect(() => {
    if (location.state?.scrollToContact) {
      scroller.scrollTo('contact-section', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: -navbarHeight,
      });
    }
    if (location.state?.scrollToAbout) {
      scroller.scrollTo('about-us-section', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: -navbarHeight,
      });
    }
    if (location.state?.scrollToProductsServices) {
      scroller.scrollTo('products-services-section', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: -navbarHeight,
      });
    }
  }, [location.state]);

  const handleScrollDown = () => {
    scroller.scrollTo('about-us-section', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -navbarHeight,
    });
  };

  const handleLearnMore = () => {
    navigate('/history', { state: { scrollToTop: true } });
  };

  const handlePro_Ser = () => {
    navigate('/products-services', { state: { scrollToTop: true } });
  };

  return (
    <div className="home">
      {/* Video Background Section */}
      <div className="video-background">
        <video autoPlay muted loop>
          <source src={FinalVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      
      {/* Overlay Section */}
      <div className="overlay-section">
        {/* Add the logo here */}
        <img src={logo} alt="Afework International Group Logo" className="overlay-logo" />
        {/* <h1>Afework International Group</h1> */}
        <p>
          At Afework International Group, we offer a comprehensive range of medical
          equipment sourced from leading manufacturers globally. Our catalogue
          includes diagnostic equipment, surgical instruments, and hospital
          furniture.
        </p>
        <button className="learn-more-button" onClick={handleLearnMore}>
          Learn More
        </button>
        <button className="scroll-down-arrow" onClick={handleScrollDown}>
          <div className="arrow"></div>
        </button>
      </div>

      <div className="content">
        {/* Introduction Section */}
        <Element name="about-us-section" className="about-us-container">
          <h2>About Us</h2>
          <p>
            At Afework International Group, we pride ourselves on being a leading import-export group with a legacy of excellence. For over {yearsInOperation} years, we have been dedicated to bridging the gap between global markets, specializing in the trade of medical goods. Our unwavering commitment to quality and service has established us as a trusted partner in the world of international trade.
          </p>
          <p>
            We are particularly focused on the import of pharmaceuticals, medicine, and medical equipment. These key sectors allow us to serve a diverse range of industries, ensuring that our clients have access to the products and services they need to thrive in a competitive global market.
          </p>
        </Element>

        {/* Detailed Information Sections */}
        <Element name="why-choose-us">
          <div className="why-choose-us section">
            <h3>Why Choose Us</h3>
            <p>
              With years of industry experience, we have established ourselves as a trusted partner for healthcare providers, offering superior medical equipment solutions. Our strengths lie in a global network of partnerships, rigorous quality assurance, and efficient logistics management.
            </p>
          </div>
        </Element>

        <div className="client-benefits section">
          <h3>Client Benefits</h3>
          <p className="right-align">
            Partnering with Afework International Group offers several advantages
            including reliability, cost efficiency, and customized solutions
            tailored to meet specific facility requirements and budget
            constraints.
          </p>
        </div>

        <div className="industries-served section">
          <h3>Industries Served</h3>
          <p>
            Our clientele spans various sectors within the healthcare industry,
            including hospitals, government institutions, and private practices,
            ensuring comprehensive solutions for diverse healthcare needs.
          </p>
        </div>

        <div className="client-testimonials section">
          <h3>Our Commitment to Excellence</h3>
          <blockquote>
            <p>
              At Afework International Group, we are dedicated to providing high-quality medical goods that enhance healthcare delivery. Our expertise in global trade, combined with a deep understanding of the medical industry, ensures that our clients receive the best products to meet their needs.
            </p>
          </blockquote>
        </div>

        {/* Products & Services Section */}
        <Element name="products-services-section" className="products-services section">
          <h2>Our Products & Services</h2>
          <p>
            At Afework International Group, we offer a comprehensive range of medical equipment sourced from leading manufacturers globally. Our catalogue includes diagnostic equipment, surgical instruments, and hospital furniture. With a commitment to quality and customer satisfaction, we ensure that all our products meet international standards and are delivered on time.
          </p>
          <button className="button" onClick={handlePro_Ser}>
            Product Catalogue
          </button>
        </Element>
      </div>
      <div>
        {/* Contact Section */}
        <Element name="contact-section">
          <div className="contact-container">
            <Contact />
          </div>
        </Element>
      </div>
    </div>
  );
};

export default Home;
